'use client';

import { useRef } from 'react';
import { useScroll } from 'framer-motion';
import { ReactLenis } from 'lenis/react';

import CategorySection from './CategorySection';

type IProjectSection = {
  categories: Array<any>;
};

export default function ProjectSection({ categories }: IProjectSection) {
  const container = useRef(null);

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end'],
  });

  return (
    <>
      <h2 className="text-center text-4xl font-bold leading-tight tracking-tight text-primary-content lg:text-5xl">
        Side Projects 👇
      </h2>
      <div className="h-24 w-full" />
      <section className="container mb-28" ref={container}>
        {categories.map((category, index) => (
          <CategorySection
            key={index}
            index={index}
            {...category}
            targetScale={1 - (categories.length - index - 1) * 0.05}
            progress={scrollYProgress}
            range={[index * 0.25, 1]}
          />
        ))}
      </section>
    </>
  );
}
