'use client';

import React, { ReactNode, useContext } from 'react';
import LenisWrapper from '@/components/providers/scroll/LenisWrapper';
import { ThemeContext } from '@/components/providers/theme/theme-provider';

const Body = ({ className, children }: { className: string; children: ReactNode }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <LenisWrapper>
      <body className={className} data-theme={theme}>
        {children}
      </body>
    </LenisWrapper>
  );
};

export default Body;
