'use client';

import { useRef } from 'react';
import Image from 'next/image';
import { easeInOut, motion, useScroll, useTransform } from 'framer-motion';

import HeroBackground from '../HeroBackground/HeroBackground';

const Intro = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ['100%', '-100%'], {
    ease: easeInOut,
  });
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '-10%'], {
    ease: easeInOut,
  });

  const position = useTransform(scrollYProgress, [0, 1], ['sticky', 'absolute'], {
    ease: easeInOut,
  });

  return (
    <section
      ref={targetRef}
      id="intro"
      className={`relative h-[300vh] w-full overflow-x-clip text-clip bg-transparent sm:h-[300vh]`}
    >
      <motion.div
        style={{ x }}
        className="sticky top-[50vh] -z-0 w-fit -translate-y-1/2 will-change-transform" // transform: translate
      >
        <p className="whitespace-nowrap text-nowrap text-6xl font-extrabold sm:text-9xl">
          {['Professional.', 'Reliable.', 'Communicative.', 'Innovative.'].map((feature) => (
            <motion.em
              key={feature}
              className="ml-2 uppercase will-change-[color,background]"
              initial={{
                color: 'rgba(0, 0, 0, 0)',
                background: 'rgba(0, 0, 0, 0)',
                WebkitTextStrokeColor: '#87CEEB', // skyblue
                WebkitTextStrokeWidth: '3px',
              }}
              whileInView={{
                color: '#87CEEB', // skyblue
                WebkitTextStrokeWidth: '0px',
                background: '#3DCFCF',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
              }}
              transition={{
                ease: 'easeInOut',
                duration: 0,
              }}
              viewport={{
                amount: 0.5,
              }}
            >
              {feature}
            </motion.em>
          ))}
        </p>
      </motion.div>

      <div
        className={
          'sticky right-0 top-[50vh] -z-10 ml-auto h-[300px] -translate-y-1/2 bg-transparent sm:h-[400px] lg:h-[600px]'
        }
      >
        <motion.div
          className="absolute right-8 top-0 aspect-square h-full w-[300px] text-clip shadow-lg will-change-[transform,opacity] sm:w-[400px] lg:w-[600px]"
          initial={{
            opacity: 0,
            x: 100,
          }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.4,
              delay: 0.2,
            },
          }}
          style={{
            y,
            position,
          }}
        >
          <Image
            className={'object-cover'}
            src={'/images/hero.webp'}
            alt={'The avatar of author'}
            fill={true}
            sizes={`
              (max-width: 768px) 300px,
              (max-width: 1024px) 400px,
              600px
            `}
            priority
          />
        </motion.div>
        <HeroBackground className="absolute left-0 top-1/2 -z-20 h-[400px] w-full -translate-y-1/2 md:h-auto lg:h-[520px]"></HeroBackground>
      </div>
    </section>
  );
};

export default Intro;
