'use client';

import { glassMorphismClass } from '@/config/constants';
import { cubicBezier, motion, useScroll, useTransform } from 'framer-motion';

import Logo from '../logos/Logo';
import { ThemeToggle } from '../providers/theme/theme-toggle';
import css from './Header.module.css';

const Header = () => {
  const headerClassName =
    `sticky top-0 left-0 z-20 flex-nowrap flex-col h-fit w-full ` + glassMorphismClass;

  const { scrollYProgress } = useScroll();
  const width = useTransform(scrollYProgress, [0, 1], ['100%', '0%'], {
    ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
  });

  return (
    <header className={headerClassName} id="header">
      <div className="h-1 w-full bg-[linear-gradient(to_right,theme(colors.indigo.400),theme(colors.indigo.100),theme(colors.sky.400),theme(colors.fuchsia.400),theme(colors.sky.400),theme(colors.indigo.100),theme(colors.indigo.400))]">
        <motion.div
          initial={{
            width: '100%',
          }}
          style={{
            width,
          }}
          className="float-right h-full bg-primary will-change-[width]"
        />
      </div>
      <nav
        className={
          'flex h-fit w-full justify-center' + ' items-center' + ' container bg-transparent py-1.5'
        }
      >
        <ul className={'flex size-full list-none flex-nowrap items-center justify-between'}>
          <li className={'cursor-pointer'}>
            <a
              aria-label="Scroll to the introduction section"
              href="#intro"
              className={
                'decoration-none h-max w-max text-lg font-bold hover:cursor-pointer' +
                ' hover:text-primary-color p-1 align-baseline' +
                glassMorphismClass
              }
            >
              <Logo
                className={
                  'h-[32px] w-[32px] cursor-pointer md:h-[48px] md:w-[48px]' +
                  ' [&>g]:fill-primary-content'
                }
              />
            </a>
          </li>
          <li className={'flex cursor-pointer gap-2 md:gap-4'}>
            <a
              className={'relative px-4 py-2 text-xs md:text-lg ' + css.bestiaButton}
              href={
                'https://binh-portfolio-website.s3.ap-southeast-1.amazonaws.com/Binh_Nguyen_SE.pdf'
              }
              rel="noreferrer"
              target={'_blank'}
            >
              <div
                className={
                  'absolute bg-primary-content before:bg-black after:bg-black ' + css.bestiaBg
                }
              />
              <span className={'text-primary'}>DOWNLOAD CV</span>
            </a>
            <ThemeToggle />
          </li>
        </ul>
      </nav>
    </header>
  );
};
export default Header;
