import Image from 'next/image';
import { glassMorphismClass } from '@/config/constants';
import { motion } from 'framer-motion';

type IPinnedProject = {
  name: string;
  techStack: Array<string>;
  images: Array<string>;
  className: string;
  challenge: string;
  imageScale: number;
};
export default function PinnedProject({
  challenge,
  name,
  techStack,
  images,
  className,
}: Readonly<IPinnedProject>) {
  const [thumbnailImage, ..._] = images;
  return (
    <div className={'flex w-full grow flex-col items-start gap-4 ' + className}>
      <div className={`aspect-video size-full max-h-[300px] overflow-hidden`}>
        <motion.div className={`relative size-full will-change-transform`}>
          <Image
            className="object-cover"
            src={thumbnailImage}
            alt={name}
            fill={true}
            sizes="(max-width: 768px) 700px"
            loading="lazy"
          />
        </motion.div>

        <div className={`absolute bottom-8 left-4 flex gap-2`}>
          {techStack.map((tech) => (
            <strong
              key={tech}
              className={`rounded-full px-3 py-1 text-sm font-extralight text-secondary-content ${glassMorphismClass} dark:bg-black/30`}
            >
              {tech}
            </strong>
          ))}
        </div>
      </div>
      <div className="w-full">
        <h3 className="mb-6 text-xl text-primary-content lg:text-3xl">{name}</h3>
        <p className="text-base font-thin text-secondary-content">{challenge}</p>
      </div>
    </div>
  );
}
